var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"elementRef",staticClass:"manual-aggregation-action-button",class:{
    disabled: _vm.disabled,
    clicked: _vm.clicked,
    small: _vm.small,
    pending: _vm.pending,
    finished: _vm.finished,
    circle: _vm.circle,
  },on:{"click":_vm.handleClick}},[_c('span',{staticClass:"fa-stack",class:{
      'fa-md': _vm.small,
      'fa-lg': !_vm.small,
    }},[_c('i',{staticClass:"far fa-circle text-primary fa-stack-2x"}),(_vm.pending)?_c('i',{staticClass:"fas fa-spinner fa-spin text-primary fa-stack-1x"}):(_vm.finished)?_c('i',{staticClass:"fas fa-check-circle text-primary fa-stack-1x"}):_c('i',{staticClass:"text-primary fa-stack-1x",class:_vm.iconClass})]),(_vm.finished)?_c('span',{key:_vm.finished ? 'OK' : _vm.label,staticClass:"animate-text font-weight-bolder"},[_vm._v(" OK ")]):_c('span',{key:_vm.finished ? 'OK' : _vm.label,staticClass:"animate-text"},[_vm._v(" "+_vm._s(_vm.label)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }