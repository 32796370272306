<script>
import ManualAggregationActionButton
from '@/components/manualAggregation/ManualAggregationActionButton.vue';
import AggregateElementContentsItem
from '@/components/manualAggregation/aggregateElement/AggregateElementContentsItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AggregateElementCard',
  props: {
    unitObject: Object,
    lowerLevelUnitObject: Object,
    unitObjectFromRelation: Object,
    detachAllFunction: Function,
    closeCurrentFunction: Function,
    reprintFunction: Function,
  },
  emits: [
    'detach-all',
  ],
  components: {
    AggregateElementContentsItem,
    ManualAggregationActionButton,
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      const v = value.toString();
      return v.charAt(0).toUpperCase() + v.slice(1);
    },
  },
  computed: {
    ...mapGetters('manualAggregation', [
      'currentId',
      'currentObject',
      'previousId',
    ]),
    maxItemsAmount() {
      return this.unitObjectFromRelation.connectionAmount;
    },
    progressPercentage() {
      return (this.containedLabels.length / this.maxItemsAmount) * 100;
    },
    canReprint() {
      return this.previousId;
    },
    canClose() {
      return this.containedLabels.length > 0;
    },
    canClear() {
      return this.containedLabels.length > 0;
    },
    currentObjectSerialNumber() {
      return this.currentObject?.serialNumber;
    },
    containedLabels() {
      return (this.currentObject?.containedLabels || [])
        .filter(x => x.unitId === this.lowerLevelUnitObject.id)
        .reverse();
    },
    reachedFullAmount() {
      return this.containedLabels.length >= this.maxItemsAmount;
    },
  },
  watch: {
    reachedFullAmount(newValue) {
      if (newValue) {
        this.closeCurrentFunction();
      }
    },
  },
};
</script>

<template>
  <div
    :key="`element-${currentId}`"
    class="aggregate-element-card"
  >
    <div class="column-title d-flex align-items-center">
      <i class="fas fa-box-open mr-1" />

      {{ unitObject.name | capitalize }}
    </div>

    <div class="sscc">
      {{ currentObjectSerialNumber }}
    </div>

    <div class="line" />

    <div class="contents-amount">
      <span>
        {{ containedLabels.length }}
        <span class="full-unit-amount">
          / {{ maxItemsAmount }}
        </span>
      </span>
    </div>

    <div class="progress-box">
      <div class="progress">
        <div
          class="progress-bar bg-primary"
          :style="`width: ${progressPercentage}%`"
        />
      </div>
    </div>

    <div class="line" />

    <p class="contents-header">
      Zawartość:
    </p>

    <div class="contents-wrapper">
      <div class="contents-list">
        <AggregateElementContentsItem
          v-for="(i, index) in containedLabels"
          :key="i.id"
          :index="containedLabels.length - index"
          :serial-number="i.id.toString()"
          :item-on-top="index === 0"
        />
      </div>
    </div>
    <div class="line" />

    <div
      class="d-flex mt-3"
      style="gap: 1rem; height: 84px"
    >
      <ManualAggregationActionButton
        icon-class="fas fa-box-taped"
        label="Zamknij"
        has-async-action
        :disabled="!canClose"
        :async-action="closeCurrentFunction"
      />

      <ManualAggregationActionButton
        icon-class="fas fa-print"
        label="Przedrukuj"
        has-async-action
        :disabled="!canReprint"
        :async-action="reprintFunction"
      />

      <ManualAggregationActionButton
        icon-class="fas fa-xmark"
        label="Wyczyść"
        has-async-action
        :disabled="!canClear"
        :async-action="detachAllFunction"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.aggregate-element-card {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100% - 174px - 3rem - 54px);
  max-height: calc(100% - 174px - 3rem - 54px);
  width: 100%;
  background-color: white;
  border-radius: 9px;
  padding: 16px;

  .line {
    border-bottom: 1px solid #F0F1F3;
  }

  .column-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-left: 1px;
  }

  .sscc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #7E7E7E;
  }

  .contents-amount {
    margin-top: 1rem;
    font-size: 56px;
    line-height: 56px;
    font-weight: 500;
    text-align: center;

    .full-unit-amount {
      font-size: 28px;
      font-weight: 400;
      color: #7E7E7E;
    }
  }

  .progress-box {
    width: 35%;
    margin: 0 auto 1.5rem auto;

    .progress {
      border-radius: 50px;
    }
  }

  .contents-header {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #7E7E7E;
  }

  .contents-wrapper {
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;

    .contents-list {
      display: flex;
      flex-direction: column;
      padding-right: 0.5rem;
    }
  }

  .separator {
    width: 1px;
    height: 100%;
    background-color: #F0F1F3;
  }
}
</style>
