import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/lang';
import labelArchive from '@/store/labelArchive';
import config from './config';
import sku from './sku';
import skuProperties from './skuProperties';
import labels from './labels';
import labelProperties from './labelProperties';
import skuPropertyTypeLabelUsages from './skuPropertyTypeLabelUsages';
import requests from './requests';
import discovery from './discovery';
import edge from './edge';
import printers from './printers';
import settings from './settings';
import lines from './lines';
import lineRules from './lineRules';
import areas from './areas';
import labelingPoints from './labelingPoints';
import lineLabelingPoint from './lineLabelingPoint';
import labelsMapping from './labelsMapping';
import orders from './orders';
import orderTypes from './orderTypes';
import time from './time';
import language from './language';
import permissions from './permissions';
import users from './users';
import userSettings from './userSettings';
import packingSettings from './packingSettings';
import manualAggregation from './manualAggregation';
import dictionaries from './dictionaries';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    time,
    skuProperties,
    sku,
    labels,
    labelProperties,
    skuPropertyTypeLabelUsages,
    requests,
    discovery,
    edge,
    printers,
    settings,
    lines,
    lineRules,
    areas,
    labelingPoints,
    lineLabelingPoint,
    labelsMapping,
    orders,
    orderTypes,
    language: language(i18n),
    permissions,
    users,
    userSettings,
    dictionaries,
    labelArchive,
    packingSettings,
    manualAggregation,
  },
});
