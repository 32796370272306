<script>
import AggregateElementContentsItem
from '@/components/manualAggregation/aggregateElement/AggregateElementContentsItem.vue';

export default {
  name: 'AggregateElementPalletCard',
  props: {
    labelArchive: Object,
    unitObject: Object,
    lowerLevelUnitObject: Object,
    unitObjectFromRelation: Object,
    closeParentFunction: Function,
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      const v = value.toString();
      return v.charAt(0).toUpperCase() + v.slice(1);
    },
  },
  components: {
    AggregateElementContentsItem,
  },
  computed: {
    maxItemsAmount() {
      return this.unitObjectFromRelation.connectionAmount;
    },
    contentsItems() {
      return this.labelArchive.containedLabels
        .filter(x => x.unitId === this.lowerLevelUnitObject.id)
        .reverse();
    },
    progressPercentage() {
      return (this.contentsItems.length / this.maxItemsAmount) * 100;
    },
    serialNumber() {
      return this.labelArchive.serialNumber;
    },
    reachedFullAmount() {
      return this.contentsItems.length >= this.maxItemsAmount;
    },
  },
  watch: {
    reachedFullAmount(newValue) {
      if (newValue) {
        this.closeParentFunction();
      }
    },
  },
};
</script>

<template>
  <div class="aggregate-element-card">
    <div class="column-title d-flex align-items-center mb-0">
      <i class="fas fa-pallet-boxes mr-1" />

      {{ unitObject.name | capitalize }}
    </div>

    <div class="sscc-button">
      {{ serialNumber }}

      <i class="fas fa-chevron-right" />
    </div>

    <div class="contents-amount">
      <span>
        {{ contentsItems.length }}
        <span class="full-unit-amount">
          / {{ maxItemsAmount }}
        </span>
      </span>
    </div>

    <div class="progress-box">
      <div class="progress">
        <div
          class="progress-bar bg-primary"
          :style="`width: ${progressPercentage}%`"
        />
      </div>
    </div>

    <div class="line" />

    <p class="contents-header">
      Zawartość palety:
    </p>

    <div class="contents-wrapper">
      <div class="contents-list">
        <AggregateElementContentsItem
          v-for="(i, index) in contentsItems"
          :key="i.id"
          :index="contentsItems.length - index"
          :serial-number="i.id.toString()"
          small
        />
      </div>
    </div>

    <div class="line" />
  </div>
</template>

<style scoped lang="scss">
.aggregate-element-card {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100% - 174px - 1rem);
  max-height: calc(100% - 174px - 1rem);
  min-width: 280px;
  max-width: 280px;
  background-color: white;
  border-radius: 9px;
  padding: 16px;

  .line {
    border-bottom: 1px solid #F0F1F3;
  }

  .sscc-button {
    border-radius: 6px;
    padding: 15px 20px;
    background-color: white;
    border: 1px solid #F0F1F3;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #F0F1F3;
    }

    i {
      font-size: 14px;
      margin-left: auto;
      opacity: 0.5;
    }
  }

  .column-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 12px;
    padding-left: 1px;
  }

  .contents-amount {
    margin-top: 1rem;
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;

    .full-unit-amount {
      font-size: 18px;
      font-weight: 400;
      color: #7E7E7E;
    }
  }

  .progress-box {
    width: 50%;
    margin: 0 auto 1.5rem auto;

    .progress-bar, .progress {
      height: 8px;
    }
  }

  .contents-header {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    color: #7E7E7E;
  }

  .contents-wrapper {
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;

    .contents-list {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;
      padding-right: 0.5rem;
    }
  }
}
</style>
