<template>
  <div class="manual-aggregation">
    <div
      v-if="initLoading"
      class="init-loading"
    >
      <i class="fas fa-loader fa-spin mb-4 fa-2xl" />
      <p>
        Ładowanie...
      </p>
    </div>

    <div
      v-else
      class="h-100 w-100"
    >
      <div
        v-if="hasValidationErrors"
        class="no-order-info"
      >
        <i class="fas fa-info-circle mb-4 fa-2xl" />
        <p v-if="lineOrders.length === 0">
          Brak aktywnego zlecenia na linii #{{ lineId }}.
        </p>
        <p v-else-if="!isValidUnit">
          Aktywne zlecenie nie zawiera jednostki o skonfigurowanym identyfikatorze {{ aggregatedUnitId }}.
        </p>
        <p v-else-if="!lowerUnit">
          Brak skonfigurowanej jednostki niższego poziomu dla jednostki o identyfikatorze {{ aggregatedUnitId }}.
        </p>

        <BButton
          variant="primary"
          size="lg"
          pill
          @click="init"
        >
          <i class="fas fa-refresh mr-1" />
          Odśwież
        </BButton>
      </div>
      <div
        v-else
        class="d-flex w-100"
      >
        <div class="mr-4">
          <ManualAggregationActionButton
            icon-class="fas fa-chevron-left"
            label="Powrót"
            class="mt-3 flex-column"
            circle
            small
            @action-success="showGoBackConfirmation = true"
          />
        </div>

        <CockpitActiveOrderCard
          class="active-order-card"
          :active-orders="lineOrders"
          :selected-labeling-point-id="labelingPointId"
          disabled
        />
      </div>

      <div
        class="h-100 w-100 d-flex"
        style="gap: 1rem"
      >
        <div class="h-100 w-100 d-flex flex-column">
          <div class="scanned-barcode-field">
            <i class="fas fa-scanner-gun text-black-50" />
            <BInput
              v-model="scannedBarcode"
              :disabled="pendingBarcode"
              placeholder="Skanuj kod..."
              @keydown.enter="handleClickEnter"
            />

            <i
              class="fas fa-times-circle fa-xl"
              :class="{
                'disabled-clear-barcode': pendingBarcode || !scannedBarcode,
              }"
              @click="scannedBarcode = ''"
            />
          </div>

          <AggregateElementCard
            v-if="currentId"
            :unit-object="unitObject"
            :lower-level-unit-object="lowerUnit"
            :unit-object-from-relation="unitObjectFromRelation"
            :detach-all-function="handleDetachAll"
            :close-current-function="() => {showConfirmCloseCurrent = true}"
            :reprint-function="handleReprintPrevious"
          />
        </div>

        <div
          v-if="higherUnit"
          class="d-flex flex-column h-100"
          style="gap: 1rem"
        >
          <AggregateElementPalletCard
            v-if="higherLevelOpenLabelArchive"
            :label-archive="higherLevelOpenLabelArchive"
            :unit-object="higherUnit"
            :lower-level-unit-object="unitObject"
            :unit-object-from-relation="higherUnit"
            :close-parent-function="handleCloseParent"
          />
        </div>
      </div>
    </div>

    <div
      v-if="barcodeValidationError"
      class="label-validation-error"
      @click="barcodeValidationError = ''"
    >
      {{ barcodeValidationError }}!

      <i class="fas fa-times close-icon-button" />
    </div>

    <div
      v-if="showThisIsLastElementOnParentAlert"
      class="center-alert"
      @click="showThisIsLastElementOnParentAlert = false"
    >
      To jest ostatni element palety!

      <i class="fas fa-times close-icon-button" />
    </div>

    <div
      v-if="showThisIsElementOnNewParentAlert"
      class="center-alert red"
    >
      Umieść element na nowej palecie!

      <i class="fas fa-times close-icon-button" />
    </div>

    <div
      v-if="showGoBackConfirmation"
      class="center-alert"
    >
      <p>
        Czy na pewno chcesz wyjść?
      </p>
      <p class="small mb-5">
        Aktualnie agregowany element zostanie wyczyszczony.
      </p>

      <div
        class="d-flex align-items-center justify-content-center"
        :style="{gap: '1rem'}"
      >
        <div
          class="btn btn-secondary"
          @click="showGoBackConfirmation = false"
        >
          Anuluj
        </div>
        <div
          class="btn btn-primary"
          @click="goBackToCockpit"
        >
          <i class="fas fa-chevron-left mr-1" />
          Wyczyść i wyjdź
        </div>
      </div>
    </div>

    <div
      v-if="showConfirmCloseCurrent"
      class="center-alert"
    >
      <p>
        Czy na pewno chcesz zamknąć aktualnie agregowany element?
      </p>
      <p class="small mb-5">
        Aktualnie agregowany element zostanie zakończony.
      </p>

      <div
        class="d-flex align-items-center justify-content-center"
        :style="{gap: '1rem'}"
      >
        <div
          class="btn btn-secondary"
          @click="showConfirmCloseCurrent = false"
        >
          Anuluj
        </div>
        <div
          class="btn btn-primary"
          @click="handlePrintCurrent"
        >
          <i class="fas fa-box-taped mr-1" />
          Zamknij
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AggregateElementCard
from '@/components/manualAggregation/aggregateElement/AggregateElementCard.vue';
import CockpitActiveOrderCard from '@/components/cockpit/activeOrder/CockpitActiveOrderCard.vue';
import AggregateElementPalletCard
from '@/components/manualAggregation/aggregateElement/AggregatePalletCard.vue';
import ManualAggregationActionButton
from '@/components/manualAggregation/ManualAggregationActionButton.vue';
import { mapActions, mapGetters } from 'vuex';
import CockpitOffcanvasTypes from '@/components/cockpit/CockpitOffcanvasTypes';

export default {
  name: 'ManualAggregationView',
  data() {
    return {
      initLoading: false,
      showWithPalletLevel: true,
      labelingPointObject: null,
      lineOrders: [],
      higherLevelLabelArchive: [],
      scannedBarcode: '',
      clearDebounce: null,
      pendingBarcode: false,
      barcodeValidationError: '',
      showThisIsLastElementOnParentAlert: false,
      showThisIsElementOnNewParentAlert: false,
      showGoBackConfirmation: false,
      showConfirmCloseCurrent: false,
    };
  },
  components: {
    ManualAggregationActionButton,
    AggregateElementPalletCard,
    CockpitActiveOrderCard,
    AggregateElementCard,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapGetters('manualAggregation', [
      'currentId',
      'currentObject',
      'previousId',
    ]),
    ...mapGetters('packingSettings', [
      'getUnitsForSku',
      'getUnitById',
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    labelingPointId() {
      return Number(this.$route.params.labelingPointId);
    },
    lineId() {
      return Number(this.$route.params.lineId);
    },
    aggregatedUnitId() {
      return Number(this.$route.params.aggregatedUnitId);
    },
    skuId() {
      return this.lineOrders[0]?.order.skuId;
    },
    orderId() {
      return this.lineOrders[0]?.order.id;
    },
    skuUnits() {
      if (!this.skuId) return [];
      return this.getUnitsForSku(this.skuId);
    },
    isValidUnit() {
      return this.skuUnits.some(unit => unit.id === this.aggregatedUnitId);
    },
    unitObject() {
      if (!this.isValidUnit) return {};
      return this.getUnitById(this.aggregatedUnitId) || {};
    },
    unitObjectFromRelation() {
      if (!this.isValidUnit) return {};
      return this.getHigherLevelUnit(this.lowerUnit.id, this.skuId);
    },
    lowerUnit() {
      if (!this.isValidUnit) return {};
      return this.getLowerLevelUnit(this.aggregatedUnitId, this.skuId);
    },
    higherUnit() {
      if (!this.isValidUnit) return {};
      return this.getHigherLevelUnit(this.aggregatedUnitId, this.skuId);
    },
    hasValidationErrors() {
      return this.lineOrders.length === 0 || !this.isValidUnit || !this.lowerUnit;
    },
    higherLevelOpenLabelArchive() {
      return this.higherLevelLabelArchive.find(x => x.labelStatus === 'Aviso');
    },
    parentId() {
      return this.higherLevelOpenLabelArchive?.id;
    },
  },
  methods: {
    ...mapActions([
      'getProdOrders',
      'getLabelingPointWithMeta',
    ]),
    ...mapActions('manualAggregation', [
      'validateBarcode',
      'fetchCurrent',
      'generateNewCurrent',
      'attachToCurrent',
      'detachAllFromCurrent',
      'printCurrent',
      'reprintPrevious',
      'attachToParent',
      'generateNewParent',
      'closeParent',
    ]),
    ...mapActions('labelArchive', [
      'getLabelArchive',
    ]),
    ...mapActions('packingSettings', [
      'getUnits',
      'getPackingSettings',
    ]),
    async goBackToCockpit() {
      await this.handleDetachAll();

      setTimeout(() => {
        this.$router.push('/cockpit');
      }, 500);
    },
    debounceClearBarcodeValidationError() {
      if (this.clearDebounce) {
        clearTimeout(this.clearDebounce);
      }

      this.clearDebounce = setTimeout(() => {
        this.barcodeValidationError = '';
      }, 3000);
    },
    async fetchProdOrders() {
      const { data } = await this.getProdOrders({
        params: {
          query: {
            lineId: this.lineId,
          },
        },
      });
      this.lineOrders = data;
    },
    async fetchLabelingPoint() {
      const { data } = await this.getLabelingPointWithMeta({
        params: {
          id: this.labelingPointId,
        },
      });
      this.labelingPointObject = data;
    },
    async fetchPackingSettings() {
      const { plantCode } = this.globalSettings;
      await this.getPackingSettings({
        params: {
          plantCode,
        },
      });
    },
    async fetchUnits() {
      const { plantCode } = this.globalSettings;
      await this.getUnits({
        params: {
          plantCode,
        },
      });
    },
    async fetchHigherLevelLabelArchive() {
      if (!this.higherUnit) return;

      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchive({
        params: {
          plantCode,
          query: {
            orderId: this.orderId,
            lineId: this.lineId,
            skuId: this.skuId,
            unitId: this.higherUnit.id,
            page_number: 1,
            page_size: 10000,
          },
        },
      });

      this.higherLevelLabelArchive = data.items;

      const isThereOpenLabel = data.items.some(x => x.labelStatus === 'Aviso');
      if (!isThereOpenLabel) {
        await this.createNewParent();
      }
    },

    // MANUAL AGGREGATION METHODS
    async createNewParent() {
      const { plantCode } = this.globalSettings;
      await this.generateNewParent({
        params: {
          plantCode,
        },
        data: {
          plantCode,
          parentSerialNumber: new Date().getTime().toString(),
          childUnitId: this.unitObject.id,
          lineId: this.lineId,
          labelingPointId: this.labelingPointId,
          orderId: this.orderId,
          batchId: '111',
          skuId: this.skuId,
          subLabelIds: [],
          labelData: [],
        },
      });
      await this.fetchHigherLevelLabelArchive();
    },
    async createNewCurrent() {
      const { plantCode } = this.globalSettings;
      await this.generateNewCurrent({
        params: {
          plantCode,
        },
        data: {
          plantCode,
          parentSerialNumber: new Date().getTime().toString(),
          childUnitId: this.lowerUnit.id,
          lineId: this.lineId,
          labelingPointId: this.labelingPointId,
          orderId: this.orderId,
          batchId: '111',
          skuId: this.skuId,
          subLabelIds: [],
          labelData: [],
        },
      });
    },
    async handleDetachAll() {
      if (!this.currentId) return;

      const { plantCode } = this.globalSettings;
      await this.detachAllFromCurrent({
        params: {
          plantCode,
          id: this.currentId,
        },
      });
      await this.updateCurrentElementData();
    },
    async handlePrintCurrent() {
      if (!this.currentId) return;

      this.showConfirmCloseCurrent = false;

      const { plantCode } = this.globalSettings;
      await this.printCurrent({
        params: {
          plantCode,
          id: this.currentId,
          query: {
            labellingPointId: this.labelingPointId,
          },
        },
      });

      await this.handleAttachToParent();
      await this.handleNewCurrent();
    },
    async handleCloseParent() {
      if (!this.parentId) return;

      const { plantCode } = this.globalSettings;
      await this.closeParent({
        params: {
          plantCode,
          id: this.parentId,
          query: {
            labellingPointId: this.labelingPointId,
          },
        },
      });

      this.showThisIsLastElementOnParentAlert = true;

      await this.createNewParent();
    },
    async handleReprintPrevious() {
      if (!this.currentId || !this.previousId) return;

      const { plantCode } = this.globalSettings;
      await this.reprintPrevious({
        params: {
          plantCode,
          id: this.previousId,
          query: {
            labellingPointId: this.labelingPointId,
          },
        },
      });
    },
    async updateCurrentElementData() {
      const { plantCode } = this.globalSettings;
      if (!this.currentId) return;

      await this.fetchHigherLevelLabelArchive();
      await this.fetchCurrent({
        params: {
          plantCode,
          id: this.currentId,
        },
      });
    },
    async handleNewCurrent() {
      await this.createNewCurrent();
      await this.updateCurrentElementData();
    },
    async handleAttachToParent() {
      if (!this.currentId || !this.parentId) return;

      await this.fetchHigherLevelLabelArchive();

      this.$nextTick(async () => {
        const { plantCode } = this.globalSettings;
        await this.attachToParent({
          params: {
            plantCode,
            id: this.parentId,
            query: {
              labelIdsToAttach: [this.currentId],
            },
          },
        });
        await this.updateCurrentElementData();
      });
    },
    async handleScan() {
      if (!this.scannedBarcode) return;

      const { plantCode } = this.globalSettings;
      const barcode = this.scannedBarcode;

      try {
        this.pendingBarcode = true;
        const { data } = await this.validateBarcode({
          params: {
            plantCode,
            query: {
              barcode,
              orderId: this.orderId,
            },
          },
        });
        await this.attachToCurrent({
          params: {
            plantCode,
            id: this.currentId,
            query: {
              labelIdsToAttach: [data.id],
            },
          },
        });
        await this.updateCurrentElementData();
      } catch (err) {
        if (err && err?.response?.data) {
          const { message } = err.response.data;
          this.barcodeValidationError = message;
        } else {
          this.barcodeValidationError = 'Unexpected label validation error';
        }
      } finally {
        this.scannedBarcode = '';
        this.pendingBarcode = false;
      }
    },

    // INITIALIZATION AND REFRESH
    async init() {
      this.initLoading = true;

      await this.fetchProdOrders();
      await this.fetchLabelingPoint();
      await this.fetchUnits();
      await this.fetchPackingSettings();

      if (!this.hasValidationErrors) {
        await this.fetchHigherLevelLabelArchive();
        await this.handleNewCurrent();
      }

      setTimeout(() => {
        this.initLoading = false;
      }, 1000);
    },

    // KEYBOARD EVENTS
    handleClickEnter() {
      this.handleScan();
    },
    handleKeyDown(event) {
      if (
        this.offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
        && this.offcanvasTabIndex === 1
      ) return;

      if (event.key === 'Enter') {
        this.handleClickEnter();
      } else {
        const isNumber = /^[0-9]$/;
        const isLetter = /^[A-Z]$/;
        const isSemiColon = /^;$/;
        const isDash = /^-$/;
        if (
          isNumber.test(event.key)
          || isLetter.test(event.key)
          || isSemiColon.test(event.key)
          || isDash.test(event.key)
        ) {
          event.preventDefault();
          this.scannedBarcode += event.key;
        }
      }
    },
    initKeyDownListener() {
      document.addEventListener(
        'keydown',
        this.handleKeyDown,
      );
    },
    removeKeyDownListener() {
      document.removeEventListener(
        'keydown',
        this.handleKeyDown,
      );
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.initKeyDownListener();
  },
  beforeDestroy() {
    this.removeKeyDownListener();
  },
};
</script>

<style scoped lang="scss">
.manual-aggregation {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(244, 244, 244);
  z-index: 10;
  padding: calc(56px + 1rem) 2rem 2rem 2rem;

  .active-order-card {
    height: 170px;
    margin-bottom: 20px;
  }

  .actions-card {
    height: 70px;
    overflow: hidden;
    width: 230px;
    padding: 1rem;
    gap: 1rem;
    background-color: white;
    border-radius: 9px;
  }

  &:deep() {
    * {
      ::-webkit-scrollbar {
        width: 14px;
      }
    }
  }
}

.init-loading, .no-order-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@keyframes fly-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.label-validation-error {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FFD2D2;
  padding: 3rem;
  text-align: center;
  font-size: 3rem;
  color: #FF0000;
  z-index: 100;
  font-weight: 500;
  border-top: 1px solid #FF0000;
  border-radius: 0 0 9px 9px;
  animation: fly-up 0.5s;
}

.center-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e0e0e0;
  padding: 4rem;
  text-align: center;
  font-size: 1.5rem;
  color: #2d2d2d;
  z-index: 100;
  font-weight: 500;
  border-radius: 9px;

  &.green {
    background-color: #e0ffbc;
    color: #224400;
  }

  &.red {
    background-color: #ffbebe;
    color: #670000;
  }
}

.close-icon-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  opacity: 0.5;
  cursor: pointer;
}

.scanned-barcode-field {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 9px;

  input {
    margin-bottom: 0;
    border: unset;
    border-radius: 9px;
    border: 1px solid #ECECEC;
  }
}

.disabled-clear-barcode {
  cursor: not-allowed;
  opacity: 0.3;
}
</style>
