<script>
import CockpitMatryoshkaColumnQueueItem
from '@/components/cockpit/matryoshka/CockpitMatryoshkaColumnQueueItem.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CockpitMatryoshkaColumn',
  props: {
    title: String,
    unit: Object,
    activeOrder: Object,
  },
  data: () => ({
    loading: false,
    showLabelPrintSelectModal: false,
    loadingLineLabelingPoints: false,
    lineLabelingPoints: [],
    labelArchive: [],
  }),
  components: {
    CockpitMatryoshkaColumnQueueItem,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapGetters('packingSettings', [
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    lineId() {
      return this.activeOrder.order.lineId;
    },
    lowerLevelUnit() {
      return this.getLowerLevelUnit(
        this.unit.id,
        this.activeOrder.order.skuId,
      );
    },
    higherLevelUnit() {
      return this.getHigherLevelUnit(
        this.unit.id,
        this.activeOrder.order.skuId,
      );
    },
    queueLength() {
      return this.filteredLabelArchive.length || 0;
    },
    palletsColumn() {
      return this.unit.name === 'paleta';
    },
    fetchParams() {
      const { plantCode } = this.globalSettings;
      return {
        plantCode,
        query: {
          skuId: this.activeOrder.order.skuId,
          unitId: this.unit.id,
          orderId: this.activeOrder.order.id,
          lineId: this.lineId,
          page_number: 1,
          page_size: 10000,
        },
      };
    },
    filteredLabelArchive() {
      if (this.palletsColumn) return [...this.labelArchive].reverse();

      return [...this.labelArchive.filter(
        item => [
          'Aviso',
          'Printed',
          'Removed',
          'Imported',
        ].includes(item.labelStatus),
      )]
        .reverse();
    },
  },
  emits: [
    'select-item',
    'manual-aggregation',
  ],
  watch: {
    fetchParams: {
      handler() {
        this.fetchLabelArchive();
      },
      deep: true,
      immediate: true,
    },
    showLabelPrintSelectModal(value) {
      if (!value) {
        this.lineLabelingPoints = [];
      } else {
        this.fetchLineLabelingPoints();
      }
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchive',
    ]),
    ...mapActions([
      'getLineLabelingPoint',
    ]),
    openLabelPrintSelectModal() {
      this.showLabelPrintSelectModal = true;
    },
    async fetchLineLabelingPoints() {
      this.loadingLineLabelingPoints = true;
      const { data } = await this.getLineLabelingPoint({
        params: {
          plantCode: this.globalSettings.plantCode,
          lineId: this.lineId,
        },
      });
      this.lineLabelingPoints = data;
      this.loadingLineLabelingPoints = false;
    },
    goToManualAggregation(labelingPoint) {
      this.$router.push(`/manual-aggregation/${this.lineId}/${labelingPoint.id}/${this.unit.id}`);
    },
    async fetchLabelArchive() {
      this.loading = true;
      const { data } = await this.getLabelArchive({
        params: {
          ...this.fetchParams,
        },
      });
      this.labelArchive = data.items;
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="column">
    <div class="column-title">
      {{ title }}
    </div>

    <div class="line" />

    <div class="column-queue-length">
      <Loader v-if="loading" />
      <span v-else>
        {{ queueLength }}
      </span>
    </div>

    <div
      v-if="lowerLevelUnit && higherLevelUnit && !palletsColumn"
      class="manual-aggregation-button"
      @click.stop="openLabelPrintSelectModal()"
    >
      <i class="fas fa-box-open" />
      Agreguj manualnie
    </div>

    <div class="line" />

    <div class="queue-heading">
      {{ $t('cockpit.queue') }}

      <div class="clear-queue-button">
        {{ $t('cockpit.clearQueue') }}
      </div>
    </div>

    <div class="line" />

    <Loader v-if="loading" />
    <div
      v-else
      class="queue-list-wrapper"
    >
      <CockpitMatryoshkaColumnQueueItem
        v-for="labelArchiveItem in filteredLabelArchive"
        :key="labelArchiveItem.id"
        :item="labelArchiveItem"
        :pallets-column="palletsColumn"
        @select-item="$emit('select-item', $event)"
      />

      <p
        v-if="filteredLabelArchive.length === 0"
        class="text-center text-black-50 p-3 mb-0 small"
      >
        Kolejka jest pusta
      </p>
    </div>

    <BModal
      v-model="showLabelPrintSelectModal"
      title="Wybierz punkt etykietowania"
      hide-footer
    >
      <div
        v-for="point in lineLabelingPoints"
        :key="point.id"
        class="labeling-point-button"
        @click="goToManualAggregation(point)"
      >
        <i class="fas fa-print mr-3" />

        <div>
          <p>
            {{ point.name }}
            <BBadge>
              {{ point.type }}
            </BBadge>
          </p>
          <p>
            {{ point.description }}
          </p>
        </div>

        <i class="fas fa-chevron-right ml-auto" />
      </div>
    </BModal>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/vars.icss";

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 250px;
  max-height: 100%;
  max-height: max-content;
  background-color: white;
  border-radius: 9px;
  padding: 16px;

  .line {
    border-bottom: 1px solid #F0F1F3;
  }

  .column-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 12px;
    padding-left: 1px;
  }

  .column-queue-length {
    font-size: 61px;
    line-height: 90px;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5rem;
  }

  .manual-aggregation-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    background-color: #EFEFEF;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 30px;
    width: 144px;
    margin: 0 auto;
    color: #7F7F7F;
    margin-bottom: 1.5rem;

    &:hover {
      background-color: #E0E0E0;
    }

    i {
      margin-right: 5px;
    }
  }

  .queue-heading {
    font-size: 14px;
    font-weight: 500;
    color: black;
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .clear-queue-button {
      color: #7CB92D;
      font-weight: 400;
      font-size: 11px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .queue-list-wrapper {
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 12px;
    padding-right: 12px;
  }
}

.labeling-point-button {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #F0F1F3;
  cursor: pointer;

  &:hover {
    background-color: #F0F1F3;
  }

  &:last-child {
    border-bottom: none;
  }

  i {
    font-size: 20px;
    margin-right: 10px;
  }

  p {
    margin: 0;
  }

  p:first-child {
    font-size: 14px;
    font-weight: 500;
  }

  p:last-child {
    font-size: 12px;
    color: #7E7E7E;
  }
}
</style>
