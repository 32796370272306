import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getLabelArchive',
    path: ({ plantCode }) => `/plant/${plantCode}/label-archive`,
  })
  .get({
    action: 'getLabelArchiveByBarcodes',
    path: ({ plantCode }) => `/plant/${plantCode}/label-archive/by-barcodes`,
  })
  .get({
    action: 'getLabelArchiveByIds',
    path: ({ plantCode }) => `/plant/${plantCode}/label-archive/by-ids`,
  })
  .get({
    action: 'getLabelArchiveItem',
    path: ({ plantCode, id }) => `/plant/${plantCode}/label-archive/${id}`,
  })
  .get({
    action: 'getLabelArchiveByContainerLabel',
    path: ({ plantCode }) => `/plant/${plantCode}/label-archive/by-container-label`,
  })
  .get({
    action: 'getLabelArchiveByContainedLabel',
    path: ({ plantCode }) => `/plant/${plantCode}/label-archive/by-contained-label`,
  })
  .put({
    action: 'updateLabelArchiveItem',
    path: ({ plantCode, id }) => `/plant/${plantCode}/label-archive/${id}`,
  })
  .put({
    action: 'attachLabelArchiveItem',
    path: ({ plantCode, id }) => `/plant/${plantCode}/label-archive/${id}/attach`,
  })
  .put({
    action: 'detachLabelArchiveItem',
    path: ({ plantCode, id }) => `/plant/${plantCode}/label-archive/${id}/detach`,
  })
  .getStore({
    namespaced: true,
  });

store.getters = {
  ...store.getters,
  labelArchiveStatuses() {
    return [
      {
        id: 'Aviso',
        name: 'Aviso',
        icon: 'fas fa-stars',
        color: '#001479',
      },
      {
        id: 'Active',
        name: 'Active',
        icon: 'fas fa-check',
        color: '#2BAF9D',
      },
      {
        id: 'Removed',
        name: 'Removed',
        icon: 'fas fa-trash',
        color: '#DD5D3E',
      },
      {
        id: 'Printed',
        name: 'Printed',
        icon: 'fas fa-barcode',
        color: '#3687C4',
      },
      {
        id: 'QualityCheck',
        name: 'QualityCheck',
        icon: 'fas fa-flask',
        color: '#3687C4',
      },
      {
        id: 'StorageSample',
        name: 'StorageSample',
        icon: 'fas fa-boxes-stacked',
        color: '#8B7BE2',
      },
      {
        id: 'Exported',
        name: 'Exported',
        icon: 'fas fa-file-excel',
        color: '#CF66B8',
      },
      {
        id: 'Imported',
        name: 'Imported',
        icon: 'fas fa-file-import',
        color: '#b99e00',
      },
      {
        id: 'Utilized',
        name: 'Utilized',
        icon: 'fas fa-recycle',
        color: '#373245',
      },
    ];
  },
};

export default store;
