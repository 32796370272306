<script>
export default {
  name: 'AggregateElementContentsItem',
  props: {
    index: Number,
    serialNumber: String,
    itemOnTop: Boolean,
    small: Boolean,
  },
};
</script>

<template>
  <div
    class="aggregate-contents-item"
    :class="{
      first: itemOnTop,
      small,
    }"
  >
    <div class="index">
      {{ index }}.
    </div>

    <p class="serial-number">
      {{ serialNumber }}
    </p>
  </div>
</template>

<style scoped lang="scss">

@keyframes flyFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
    font-size: 0;
    height: 0;
    border-width: 0;
    margin-bottom: 0;
  }

  70% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    font-size: 0.8rem;
    height: 33px;
    border-width: 1px;
    margin-bottom: 0.25rem;
  }
}

@keyframes flash {
  0% {
    background-color: #FFFFFF;
  }

  50% {
    background-color: #e7ffca;
  }

  100% {
    background-color: #FFFFFF;
  }
}

@keyframes flash-index {
  0% {
    background-color: #eee;
  }

  50% {
    background-color: #82ac48;
  }

  100% {
    background-color: #eee;
  }
}

.aggregate-contents-item {
  width: 100%;
  height: 33px;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  overflow: hidden;
  margin-bottom: 0.25rem;

  &.first {
    animation: flyFromTop 0.75s, flash 1s;

    .index {
      animation: flash-index 1s;
    }
  }

  &.small {
    height: 25px;
    font-size: 0.7rem;
    color: #3F3F3F;

    .index {
      font-weight: 400;
      min-width: 35px;
      max-width: 35px;
    }
  }

  .serial-number {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 0.5rem;
    margin-bottom: 0;
  }

  .index {
    margin-right: 0.75rem;
    background-color: #eee;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 42px;
    max-width: 42px;
    border-radius: 5px 0 0 5px;
    color: #333333;
    font-weight: 600;
  }
}
</style>
