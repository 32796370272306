import { render, staticRenderFns } from "./CockpitActiveOrderCard.vue?vue&type=template&id=38e8102d&scoped=true"
import script from "./CockpitActiveOrderCard.vue?vue&type=script&lang=js"
export * from "./CockpitActiveOrderCard.vue?vue&type=script&lang=js"
import style0 from "./CockpitActiveOrderCard.vue?vue&type=style&index=0&id=38e8102d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e8102d",
  null
  
)

export default component.exports