<script>
export default {
  name: 'ManualAggregationActionButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: 'fas fa-scanner-gun',
    },
    label: {
      type: String,
      default: 'Jakaś akcja',
    },
    hasAsyncAction: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    asyncAction: {
      type: Function,
      default: () => {},
    },
  },
  emits: [
    'action-success',
  ],
  data: () => ({
    clicked: false,
    pending: false,
    finished: false,
  }),
  methods: {
    async handleClick() {
      if (this.disabled || this.clicked || this.pending || this.finished) {
        return;
      }

      this.clicked = true;

      setTimeout(() => {
        this.clicked = false;
      }, 300);

      if (this.asyncAction) {
        this.pending = true;

        await this.asyncAction();

        setTimeout(() => {
          this.$emit('action-success');
          this.pending = false;

          this.handleShowFinished();
        }, 100);
      } else {
        this.$emit('action-success');
      }
    },
    handleShowFinished() {
      this.finished = true;

      setTimeout(() => {
        this.finished = false;
      }, 1000);
    },
  },
};
</script>

<template>
  <div
    ref="elementRef"
    class="manual-aggregation-action-button"
    :class="{
      disabled,
      clicked,
      small,
      pending,
      finished,
      circle,
    }"
    @click="handleClick"
  >
    <span
      class="fa-stack"
      :class="{
        'fa-md': small,
        'fa-lg': !small,
      }"
    >
      <i class="far fa-circle text-primary fa-stack-2x" />

      <i
        v-if="pending"
        class="fas fa-spinner fa-spin text-primary fa-stack-1x"
      />
      <i
        v-else-if="finished"
        class="fas fa-check-circle text-primary fa-stack-1x"
      />
      <i
        v-else
        :class="iconClass"
        class="text-primary fa-stack-1x"
      />
    </span>

    <span
      v-if="finished"
      :key="finished ? 'OK' : label"
      class="animate-text font-weight-bolder"
    >
      OK
    </span>
    <span
      v-else
      :key="finished ? 'OK' : label"
      class="animate-text"
    >
      {{ label }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.manual-aggregation-action-button {
  padding: 0.75rem;
  border-radius: 500px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 0.75rem;
  background-color: #929292;
  border-width: 1px;
  border-style: solid;
  border-color: #929292;
  color: white;
  //color: #494949;

  &.circle {
    width: 70px;
    height: 70px;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &.clicked, &.pending {
    scale: 0.9;
    opacity: 1;
    background-color: #656565;
  }

  &.finished {
    opacity: 1;
    background-color: #6e6e6e;
  }

  &.small {
    padding: 0.4rem;
  }

  .fa-stack {
    width: 40px;
  }

  span {
    width: 100%;
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    i {
      color: white !important;
    }
  }
}

@keyframes animateShowText {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-text {
  animation: animateShowText 0.5s;
}
</style>
