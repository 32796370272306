<script>

import moment from 'moment/moment';
import { mapGetters, mapState } from 'vuex';
import MarkingStatusBadge from '@/components/markings/MarkingStatusBadge.vue';

export default {
  name: 'MarkingsListItem',
  props: {
    item: Object,
    isCompatibilityView: Boolean,
    isCompatibilityCorrect: Boolean,
    loadingParents: Boolean,
    parentsList: Array,
    hideActions: Boolean,
    disabled: Boolean,
  },
  components: { MarkingStatusBadge },
  emits: [
    'select-item',
    'select-item-contents',
  ],
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
      labelingPoints: state => state.labelingPoints.list || [],
    }),
    ...mapGetters('packingSettings', [
      'getUnitById',
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    lineName() {
      return this.lines.find(l => l.id === this.item.lineId)?.name || '?';
    },
    labelingPointName() {
      return this.labelingPoints.find(l => l.id === this.item.labelingPointId)?.name || '?';
    },
    itemUnit() {
      return this.getUnitById(this.item.unitId);
    },
    lowerLevelUnit() {
      return this.getLowerLevelUnit(
        this.item.unitId,
        this.item.skuId,
      );
    },
    upperLevelUnit() {
      return this.getHigherLevelUnit(
        this.item.unitId,
        this.item.skuId,
      );
    },
    itemParent() {
      if (!this.item.containers) return {};
      const parentRelation = this.item.containers
        .find(c => c.containerUnitId === this.upperLevelUnit?.id) || {};
      return this.parentsList.find(({ id }) => parentRelation.containerId === id) || {};
    },
    itemChildren() {
      if (!this.item.containedLabels) return [];
      return this.item.containedLabels
        .filter(c => c.unitId === this.lowerLevelUnit?.id);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('LLL');
    },
    handleClick() {
      if (this.disabled) return;
      this.$emit('select-item', this.item.id);
    },
    handleClickOnChildren() {
      this.$emit('select-item-contents', this.item.id);
    },
    handleParentClick() {
      if (!this.itemParent.serialNumber) {
        this.$emit('select-item-contents', this.item.id);
      } else {
        this.$emit('select-item', this.itemParent.id);
      }
    },
    handleOpenById(id) {
      this.$emit('select-item', id);
    },
  },
};
</script>

<template>
  <div
    v-tippy
    class="markings-list-item"
    :class="{
      disabled,
    }"
    :content="(itemUnit?.name || '?').toUpperCase() || ''"
    @click.stop="handleClick"
  >
    <div class="markings-list-item-flex">
      <div>
        <MarkingStatusBadge
          :status="item.labelStatus"
        />

        <div class="number">
          {{ item.serialNumber }}
        </div>
      </div>

      <div
        v-if="upperLevelUnit && !isCompatibilityView && !hideActions"
        v-tippy
        class="parent-number"
        :class="{
          'no-parent': !itemParent.serialNumber,
        }"
        :content="(upperLevelUnit?.name || '?').toUpperCase() || ''"
        @click.stop.prevent="handleParentClick"
      >
        <i
          v-if="loadingParents"
          class="fas fa-loader fa-spin"
        />
        <span v-else>
          <i
            v-if="itemParent.serialNumber"
            class="fas fa-arrow-up-wide-short"
          />
          {{ itemParent.serialNumber || $t('markings.noConnectionToParentElement') }}
        </span>
      </div>

      <div
        v-if="lowerLevelUnit && !isCompatibilityView"
        v-tippy
        class="children-numbers"
        :content="(lowerLevelUnit?.name || '?').toUpperCase()"
        @click.stop.prevent="handleClickOnChildren"
      >
        <i class="fas fa-arrow-down-wide-short" />
        {{ itemChildren.length }}
      </div>

      <div class="date">
        <i class="far fa-calendar" />

        {{ formatDate(item.created) }}
      </div>

      <div class="order-id">
        <span class="label">
          Order ID:
        </span>

        ?
      </div>

      <div class="line">
        {{ lineName }}

        <span class="subline-name">
          /
          {{ labelingPointName }}
        </span>
      </div>
    </div>
    <div
      v-if="isCompatibilityView"
      class="compatibility-row"
    >
      <div class="compatibility-parent-box">
        <i class="fas fa-arrow-up-wide-short" />
        <div
          :key="itemParent.id"
          v-tippy
          :content="isCompatibilityCorrect
            ? `${$t('cockpit.details')} - ${(upperLevelUnit?.name || '').toUpperCase() || ''}`
            : (`${$t('markings.cannotAccessParentElementDetails')}
              (${(upperLevelUnit?.name || '').toUpperCase() || ''}), ` +
              `${$t('markings.becauseNotAllScannedElementsHaveTheSameParentNumber')}.`)"
          class="number"
          :class="{
            'the-same-parent': isCompatibilityCorrect,
          }"
          @click.stop.prevent="isCompatibilityCorrect ? handleOpenById(itemParent.id) : null"
        >
          <span
            v-if="upperLevelUnit"
            class="unit"
          >
            {{ upperLevelUnit?.name.toUpperCase() || '' }}:
          </span>
          {{ itemParent.serialNumber || $t('markings.noConnectionToParentElement') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.markings-list-item {
  border: 1px solid #F0F1F3;
  background-color: white;
  padding: 20px 25px;
  font-size: 13px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 #EEEFF0;
  cursor: pointer;

  @media (max-width: 1100px) {
    padding: 15px 20px;
    font-size: 11px;
  }

  .markings-list-item-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .compatibility-row {
    margin-top: 10px;

    .compatibility-parent-box {
      font-size: 13px;
      display: flex;
      align-items: center;

      i {
        color: #595959;
      }

      .number {
        color: black;
        font-weight: 500;
        margin-left: 10px;
        padding: 5px 15px;
        background-color: rgba(#ed3028, 0.2);
        border-radius: 50px;
        transition: 0.3s all;

        .unit {
          font-weight: 400;
          color: #424242;
          font-size: 11px;
          margin-right: 5px;
        }

        &.the-same-parent {
          cursor: pointer;
          background-color: rgba(#7CB92D, 0.2);

          &:hover {
            background-color: rgba(#7CB92D, 0.5);
            scale: 1.05;
          }
        }
      }
    }
  }

  &:hover:not(.disabled) {
    background-color: #fefefe;
    border: 1px solid #7CB92D;
    box-shadow: 0 1px 2px 0 rgba(#7CB92D, 0.25);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .number {
    font-weight: 500;
  }

  .children-numbers, .parent-number {
    font-weight: 500;
    min-width: 50px;
    max-width: 50px;
    transition: 0.2s all;
    border-radius: 6px;
    display: inline-block;

    &:hover {
      background-color: #c2c2c2;
      padding: 3px;
    }

    &.parent-number {
      min-width: 170px;
      max-width: 170px;

      &.no-parent {
        font-size: 11px;
        font-weight: 400;
        color: #8A8A8A;
      }
    }

    i {
      margin-right: 4px;
    }
  }

  .date {
    font-size: 11px;
    color: #8A8A8A;

    i {
      margin-right: 2px;
    }
  }

  .order-id {
    font-size: 11px;
    color: #7E7E7E;

    .label {
      font-weight: 500;
    }
  }

  .line {
    font-size: 13px;
    color: black;
    font-weight: 500;

    @media (max-width: 1100px) {
      font-size: 10px;
    }

    .subline-name {
      font-size: 11px;
      font-weight: 400;
      color: #7E7E7E;
    }
  }

  .item-actions {
    display: flex;
    gap: 12px;

    .item-action {
      width: 32px;
      height: 32px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      color: #585858;
      background-color: #EFEFEF;
      cursor: pointer;

      &:hover {
        background-color: #E0E0E0;
      }
    }
  }
}
</style>
