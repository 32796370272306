import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    currentId: null,
    currentObject: null,

    previousId: null,
  },
})
  .post({
    action: 'validateBarcode',
    path: ({ plantCode }) => `/plant/${plantCode}/label-archive/validate/barcode`,
  })
  .post({
    action: 'generateNewCurrent',
    path: ({ plantCode }) => `/plant/${plantCode}/parent-label-archive/generate`,
    onSuccess(state, payload) {
      const { data } = payload;
      state.previousId = state.currentId;
      state.currentId = data.id;
    },
  })
  .post({
    action: 'generateNewParent',
    path: ({ plantCode }) => `/plant/${plantCode}/parent-label-archive/generate`,
  })
  .get({
    action: 'fetchCurrent',
    path: ({ plantCode, id }) => `/plant/${plantCode}/label-archive/${id}`,
    onSuccess(state, payload) {
      const { data } = payload;
      state.currentObject = data;
    },
  })
  .put({
    action: 'attachToCurrent',
    path: ({ plantCode, id }) => `/plant/${plantCode}/parent-label-archive/${id}/attach`,
  })
  .put({
    action: 'attachToParent',
    path: ({ plantCode, id }) => `/plant/${plantCode}/parent-label-archive/${id}/attach`,
  })
  .put({
    action: 'detachAllFromCurrent',
    path: ({ plantCode, id }) => `/plant/${plantCode}/parent-label-archive/${id}/detach/all`,
  })
  .put({
    action: 'printCurrent',
    path: ({ plantCode, id }) => `/plant/${plantCode}/parent-label-archive/${id}/print`,
  })
  .put({
    action: 'closeParent',
    // TODO TRZEBA PRZEROBIC NA JAKIŚ "CLOSE PARENT" CZY COŚ
    path: ({ plantCode, id }) => `/plant/${plantCode}/parent-label-archive/${id}/print`,
  })
  .put({
    action: 'reprintPrevious',
    path: ({ plantCode, id }) => `/plant/${plantCode}/parent-label-archive/${id}/reprint`,
  })
  .getStore({
    namespaced: true,
  });

store.getters = {
  currentId: s => s.currentId,
  currentObject: s => s.currentObject,

  previousId: s => s.previousId,
};

export default store;
